import './common/base.js'
import './price.js'
import './login.js'
import '@/scss/common/base.scss'
import '@/scss/index.scss'
import { getUIActionNews } from "./news"
import 'wowjs/css/libs/animate.css'
import wow from 'wowjs'
getUIActionNews().then(newList => {
  const list = [
    ...newList
  ]
  let html = ``
  list.forEach(item => {
    html+=`<li><a href="./news.html?id=${item.Id}" target="_blank">${item.title}</a></li>`
  })
  $('.news-section .news-list').append(html)
})
$(function () {
  new wow.WOW().init();
})